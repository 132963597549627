import Http from "@/services/Http";
export default {
  getParentDiscussions(data) {
    return Http().post("/discussion/list", data);
  },
  getDiscussionDetails(data) {
    return Http().post("/discussion/details", data);
  },
  saveComment(data) {
    return Http().post("/discussion/save/comment", data);
  },
  getTeachers(data) {
    return Http().post("/discussion/teachers", data);
  },
  saveDiscussion(data) {
    return Http().post("/discussion/create", data);
  }
};
