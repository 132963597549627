<template>
  <div class="discussion-content">
    <div class="discussion-wrap">
      <div class="__header">
        <!-- <h4>Discussion board</h4> -->
        <button @click="$router.go(-1)" class="btn cta-small" type="button">
          <i class="fas fa-chevron-left pr-2"></i>
          Back to discussion board
        </button>
      </div>
      <div class="__body" v-if="isLoading == false">
        <a class="discussion-card card-hero">
          <div class="stamp">{{ discussion.created_date }}</div>
          <div class="thumbnail">
            <img :src="discussion.image" alt="" />
          </div>
          <div class="_card-body row">
            <div class="discuss-body col-md-12">
              <div class="header">
                <h5>{{ discussion.first_name }} {{ discussion.last_name }}</h5>
                <span class="physics">{{ discussion.subject }}</span>
              </div>
              <p class="status">
                {{ discussion.created_date }} - {{ discussion.time }}
              </p>
              <p class="body">
                {{ discussion.discussion }}
              </p>
            </div>
          </div>
        </a>
        <ul class="list-unstyled comment-group">
          <li
            v-for="(child, childIndex) in discussion.children"
            :key="childIndex"
          >
            <a class="discussion-card card-hero">
              <div class="thumbnail">
                <img :src="child.image" alt="" />
              </div>
              <div class="_card-body row">
                <div class="discuss-body col-md-12">
                  <div class="header">
                    <h6>{{ child.first_name }} {{ discussion.last_name }}</h6>
                    <p class="date">{{ child.created_date }}</p>
                  </div>
                  <!-- <p class="status">Latest reply from @Tom 2min ago</p> -->
                  <p class="body">
                    {{ child.discussion }}
                  </p>
                </div>
              </div>
            </a>
          </li>
        </ul>

        <div class="input-wrapper">
          <div class="form-floating">
            <textarea
              class="form-control"
              v-model="comments"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
              style="height: 100px"
            ></textarea>
            <label for="floatingTextarea2">Comments</label>
          </div>
          <div v-if="errors && errors.discussion" class="error-msg">
            {{ errors.discussion[0] }}
          </div>
          <div class="btn-wrap mt-3">
            <button
              class="cta-primary btn m-auto"
              @click.prevent="saveComment"
              :disabled="isSubmitting == true"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <webinar-loader v-else></webinar-loader>
    </div>
  </div>
</template>

<script>
import DiscussionService from "@/services/DiscussionService";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import errorLog from "@/errorLog";
export default {
  name: "DiscussionSingle",
  components: {
    WebinarLoader
  },
  created() {
    this.getDiscussion();
  },
  data() {
    return {
      discussion: [],
      isLoading: true,
      isSubmitting: false,
      comments: "",
      discussionId: this.$route.params.id,
      errors: {}
    };
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isPupaMember"]);
      return this.$store.getters["privilege/isPupaMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isPupaMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  methods: {
    getDiscussion() {
      const data = {
        discussion_id: this.discussionId
      };
      DiscussionService.getDiscussionDetails(data)
        .then(result => {
          this.discussion = result.data;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
          errorLog.log(error);
        });
    },
    saveComment() {
      if (this.comments == "") {
        this.$toast.error("Please enter comment");
      } else {
        const data = {
          discussion_id: this.discussionId,
          discussion: this.comments
        };
        this.isSubmitting = true;
        DiscussionService.saveComment(data)
          .then(result => {
            this.isSubmitting = false;
            if (result.data.status == "error") {
              this.$toast.error(result.data.message);
            } else if (result.data.status == "validation_error") {
              this.errors = result.data.message || {};
            } else {
              this.getDiscussion();
              this.comments = "";
              this.$toast.success(result.data.message);
            }
          })
          .catch(error => {
            this.isLoading = false;
            errorLog.log(error);
          });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/style/discussion-board.scss";
</style>
